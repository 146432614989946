import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar } from 'recharts';

const GraphData = ({ graphData, isMonthDataVisible, showMonthData, selectedTimePeriod, setSelectedTimePeriod }) => {
  // Colors for the chart
  const chartColors = {
    uptime: "#82ca9d",    // Green for uptime
    downtime: "#ff6b6b",  // Red for downtime
  };

  return (
    <div className='items-center py-3 pb-8'>
      <div className="flex justify-between items-end">
        <h5 onClick={showMonthData} className="py-3 m-0 px-8 flex-grow text-blueGray-500 bg-secondary rounded-t-2xl drop-shadow font-bold no-underline cursor-pointer flex items-center justify-between">
          <span>Uptime Stats</span>
          <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isMonthDataVisible ? 'rotate-90' : ''}`} />
        </h5>
      </div>
      <div className={`border-solid border border-white border-opacity-30 border-t-0 p-5 ${isMonthDataVisible ? '' : 'hidden'}`}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={graphData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
            stackOffset="expand"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="created" 
              label={{ 
                value: selectedTimePeriod === '1_day' ? 'Time (24h format)' : 
                      selectedTimePeriod === '7_days' ? 'Day and Time' : 
                      'Date', 
                position: 'insideBottomRight', 
                offset: -10 
              }}
            />
            <YAxis 
              label={{ value: 'Time Distribution', angle: -90, position: 'insideLeft' }}
              tickFormatter={(value) => `${Math.round(value * 100)}%`}
            />
            <Tooltip 
              formatter={(value, name, props) => {
                // Check if props and props.payload exist and have at least one item
                if (!props || !props.payload || !props.payload.length) {
                  return [value, name];
                }
                
                const entry = props.payload[0].payload;
                if (!entry) {
                  return [value, name];
                }
                
                const total = (entry.uptime || 0) + (entry.downtime || 0);
                if (total === 0) {
                  return [value, name];
                }
                
                if (name === 'uptime' && entry.uptime !== undefined) {
                  const percentage = Math.round((entry.uptime / total) * 100);
                  return [`${entry.uptime} minutes (${percentage}%)`, 'Uptime'];
                } else if (name === 'downtime' && entry.downtime !== undefined) {
                  const percentage = Math.round((entry.downtime / total) * 100);
                  return [`${entry.downtime} minutes (${percentage}%)`, 'Downtime'];
                }
                return [value, name];
              }}
              labelFormatter={(label) => {
                return `Time Block: ${label}`;
              }}
            />
            <Legend />
            <Bar 
              dataKey="uptime" 
              name="Uptime" 
              stackId="a"
              fill={chartColors.uptime}
              isAnimationActive={false}
            />
            <Bar 
              dataKey="downtime" 
              name="Downtime" 
              stackId="a"
              fill={chartColors.downtime}
              isAnimationActive={false}
            />
          </BarChart>
        </ResponsiveContainer>
        <div className="ml-4 mb-5">
          <select 
            className="bg-secondary text-blueGray-500 py-3 px-4 rounded-2xl drop-shadow font-bold cursor-pointer"
            value={selectedTimePeriod}
            onChange={(e) => setSelectedTimePeriod(e.target.value)}
          >
            <option value="1_day">Last 24 Hours</option>
            <option value="7_days">Last 7 Days</option>
            <option value="30_days">Last 30 Days</option>
            <option value="90_days">Last 90 Days</option>
          </select>
        </div>
        <div className="mb-4">
          <p className="text-sm dark:text-white mb-2">
            This chart shows your website's uptime and downtime over the {
              selectedTimePeriod === '1_day' ? 'last 24 hours' : 
              selectedTimePeriod === '7_days' ? 'last 7 days' : 
              selectedTimePeriod === '30_days' ? 'last 30 days' : 
              'last 90 days'
            }. 
            <span className="block mt-1">
              <span className="inline-block w-3 h-3 bg-green-500 rounded-full mr-1"></span> Green portions show how much time your site was up
              <span className="inline-block ml-4 w-3 h-3 bg-red-500 rounded-full mr-1"></span> Red portions show how much time your site was down
            </span>
            <span className="block mt-1 italic text-xs">
              Note: Each bar represents a time block. The proportion of green to red shows the estimated uptime vs. downtime during that period based on our monitoring checks.
            </span>
          </p>
        </div>
      </div>
      <div className={!isMonthDataVisible ? '' : 'hidden'}>
        <div className="flex justify-center items-center h-40">
          <p className="text-center dark:text-white">
            Click "Uptime Stats" above to view your website's uptime and downtime data.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GraphData;
