import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Failures = ({ failures, isFailuresVisible, showFailures, mode }) => {
  const failureColumns = [
    { field: 'response', headerName: 'Error Message', flex: 2 },
    { field: 'notified', headerName: 'User Notified', flex: 1 },
    { field: 'created', headerName: 'Date', flex: 1 }
  ];

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  return (
    <div className='items-center py-3 pb-8'>
      <h5 onClick={showFailures} className="py-3 px-8 mb-0 md:min-w-full text-blueGray-500 bg-secondary rounded-t-2xl drop-shadow font-bold no-underline cursor-pointer flex items-center justify-between">
        <span>Documented Failures</span>
        <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isFailuresVisible ? 'rotate-90' : ''}`} />
      </h5>
      <div className={isFailuresVisible ? '' : 'hidden'}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={failures}
            columns={failureColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 15]}
            className='dark:text-white'
          />
        </ThemeProvider>
      </div>
      <p className='dark:text-white mt-5 text-sm'>Below is a list of failed checks logged by our system. </p>
    </div>
  );
};

export default Failures;
