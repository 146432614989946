import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaAngleRight, FaSpinner, FaArrowLeft } from "react-icons/fa";

import { useSettings } from '../../context/SettingsContext'
import Button from './Button';
import { API_URL } from './Constants';

const Sidebar = ({ reload, menuOpen, menuToggle }) => {
  const settings = useSettings();
  const [sites, setSites] = useState();
  const [isSitesVisible, setSitesVisible] = useState(true);

  /**
   * 
   */
  useEffect(() => { 
    
  }, []);

  /**
   * 
   */
  useEffect(() => {
    const token = settings.userToken;

    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(API_URL + '/getallsites', requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 200) {
            setSites(result.sites)
          } else {
            //console.log(result)
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [reload]);

  /**
   * 
   */
  const showSites = () => {
    setSitesVisible(prevVisible => !prevVisible);  // toggle visibility
  };
    
  /**
   * 
   */
  return (
    <aside className={`py-4 pl-6 pr-4 xl:pl-10 xl:pr-6 top-0 bottom-0 lg:w-64 xl:w-72 dark:bg-primary bg-secondary border-r border-opacity-50 border-white left-0 absolute flex-row flex-nowrap z-9999 transition-all duration-300 ease-in-out transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <button className="z-9999 absolute top-3 right-3 ml-auto cursor-pointer text-white text-xl leading-none rounded bg-transparent block outline-none focus:outline-none text-blueGray-300 md:hidden" type="button" onClick={menuToggle}>
        <FaArrowLeft />
      </button>
      <div className="flex-col h-full px-0 flex flex-wrap items-center justify-between w-full mx-auto overflow-y-auto overflow-x-hidden ">
        <div className="flex flex-col opacity-100 relative overflow-y-auto overflow-x-hidden h-auto z-40 items-center flex-1 rounded w-full xl:mr-1 xl:pr-1">
          <Link to={"/"} className="max-w-full rounded">
            <img src="/images/logo-icon.svg" alt="TheUptime.Bot" className='w-12 mx-auto block mt-5' />
            <h1 className='text-center text-3xl mb-0 mt-3 dark:text-white'>TheUptime.Bot</h1>
            <h3 className='text-center text-xs uppercase dark:text-white mb-10'>Get Ahead of the downtime.</h3>
          </Link>
          <div className="md:flex-col md:min-w-full flex flex-col list-none">
            <Button href="/add/" title="Add Site" icon="FaCirclePlus" extraClasses="py-2 px-4" />
            
            {sites && (
              <div>
                <h6 onClick={showSites} className="px-1 py-2 md:min-w-full text-blueGray-500 text-xs uppercase font-bold pt-4 no-underline cursor-pointer flex justify-between mt-8 border-solid border-b dark:border-white border-primary">
                  <span className='dark:text-white text-gray-900'>Your sites</span>
                  <FaAngleRight className={`text-sm dark:text-white text-gray-900 mx-2 transition-transform duration-300 ${isSitesVisible ? 'rotate-90' : ''}`} />
                </h6>
                {sites && sites.length > 0 ? (
                  <ul id='sites' className={`w-full ${isSitesVisible ? '' : 'hidden'}`}>
                  {sites.map((site, index) => (
                    <li key={index}>
                      <Link to={"/site/"+site.id} className="text-xs uppercase py-1 font-bold flex justify-between">
                        <span className='max-w-160 overflow-x-clip text-gray-900 dark:text-white'>{site.title}</span>
                        <FaArrowRight className='mr-2 text-sm text-accent ' />
                      </Link>
                    </li>
                  ))}
                  </ul>
                ) : (
                  <p className='text-blueGray-500 text-xs flex'>
                    <span>Loading Sites...</span>
                    <FaSpinner className='text-sm ml-2 animate-spin' />
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
