import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Notifications = ({ notifications, isNotificationsVisible, showNotificationData, mode }) => {
  const notificationColumns = [
    { field: 'type', headerName: 'Delivery Type', flex: 1 },
    { field: 'message', headerName: 'Message', flex: 2 },
    { field: 'sent', headerName: 'Sent', flex: 1 }
  ];

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  return (
    <div className='items-center py-3 pb-8'>
      <h5 onClick={showNotificationData} className="py-3 px-8 mb-0 md:min-w-full text-blueGray-500 bg-secondary rounded-t-2xl drop-shadow font-bold no-underline cursor-pointer flex items-center justify-between">
        <span>Notifications</span>
        <FaAngleRight className={`text-sm mx-2 transition-transform duration-300 ${isNotificationsVisible ? 'rotate-90' : ''}`} />
      </h5>
      <div className={isNotificationsVisible ? '' : 'hidden'}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={notifications}
            columns={notificationColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 15]}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Notifications;
