import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { FaPowerOff, FaSpinner } from "react-icons/fa";
import Alert from '@mui/material/Alert';

import { useSettings } from '../context/SettingsContext'
import { API_URL } from './parts/Constants';
import GraphData from './parts/GraphData';
import Failures from './parts/Failures';
import Notifications from './parts/Notifications';

const Site = ({ refreshSidebar }) => {
  const [mode, setMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  const settings = useSettings();
  //const navigate = useNavigate();
  const { id } = useParams();
  const token = settings.userToken;
  const [data, setData] = useState(null);
  const [failures, setFailures] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [graphData, setGraphData] = useState(null);

  const [isFailuresVisible, setFailuresVisible] = useState(true);
  const [isNotificationsVisible, setNotificationsVisible] = useState(true);
  const [isMonthDataVisible, setMonthDataVisible] = useState(true);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('1_day');
  

  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingTitle, setDeletingTitle] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [showError, setShowError] = useState(false)

  /**
   * 
   */
  useEffect(() => {
    // doc title
    document.title = "Site Data | SiteStatusMontoring.com"

    // detect dark mode
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = () => {
      setMode(mediaQuery.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);

    if (token) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("id", id);
      formdata.append("time_period", selectedTimePeriod);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formdata
      };

      fetch(API_URL + '/getsite', requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 200) {
            if (result.site) {
              setData(result.site)
            }
            
            if (result.failures) {
              setFailures(result.failures)
            } else {
              setFailures(null)
            }
            
            if (result.notifications) {
              setNotifications(result.notifications)
            } else {
              setNotifications(null)
            }
            
            if (result.checks) {
              setGraphData(result.checks)
            } else {
              setGraphData(null)
            }
          } else {
            //console.log(result)
          }
        })
        .catch(error => console.log('error', error));
    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [id, selectedTimePeriod]);


  /**
   * 
   */
  const showFailures = () => {
    setFailuresVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * 
   */
  const showNotificationData = () => {
    setNotificationsVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * 
   */
  const showMonthData = () => {
    setMonthDataVisible(prevVisible => !prevVisible);  // toggle visibility
  };

  /**
   * Check if a site was created less than 24 hours ago
   * @param {string} dateString - The date string from the API
   * @returns {boolean} - True if the site is less than 24 hours old
   */
  const isNewSite = (dateString) => {
    const siteCreationDate = new Date(dateString);
    const now = new Date();
    const diffInHours = (now - siteCreationDate) / (1000 * 60 * 60);
    return diffInHours < 24;
  };

  /**
   * 
   */
  const handleDelete = () => {
    setIsDeleting(true)
    const userConfirmation = window.confirm("Are you sure you want to proceed? Deleting a site will remove all history and information related to it from our application.");

    if (userConfirmation) {
      var title = data.title;
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var formdata = new FormData();
      formdata.append("site_id", id);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(API_URL + "/deletesite", requestOptions)
        .then(response => response.json())
        .then(result => {
          setIsDeleting(false)

          if (result.code == 200) {
            refreshSidebar()
            //navigate('/')
            setDeletingTitle(title)
          } else {
            setAlertMessage(result.message)
            setShowError(true)
          }
        })
        .catch(error => console.log('error', error));
    } else {
      setIsDeleting(false)
    }
  }

  return (
    <div className="relative pt-24 pb-32 dark:bg-primary">
      {deletingTitle ? (
        <div className="px-4 md:px-10 max-w-[1400px] mx-5 md:mx-auto">
          <div className='items-center py-3 px-8 text-center pb-8'>
            <h2 className='mb-12 dark:text-white'>{deletingTitle} and its data has been removed.</h2>
            <p className='text-lg'><Link className="dark:text-white underline" to={"/"}>Return to home &raquo;</Link></p>
          </div>
        </div>
      ) : data ? (
        <div>
          <div className="px-4 md:px-10 max-w-[1400px] mx-5 md:mx-auto">
            {showError ? (
              <Alert severity="error" variant="filled" className='my-12' onClose={() => {setShowError(false)}}>
                {alertMessage}
              </Alert>
            ) : (<></>)}

            <div className='flex justify-between items-center py-3 px-8 bg-secondary rounded-2xl drop-shadow'>
              <h1 className='my-0'>{data.title}</h1>
              <div className='flex justify-end items-center ml-2'>
                <span className='text-sm'>Current Status:</span>
                <FaPowerOff title='Current Status' className={`ml-2 text-xl ${(data.status == 1) ? 'text-green-500' : 'text-red-500'}`} />
              </div>
            </div>
            <p className='text-right mt-2 mb-8 text-sm'>Last Check: {data.last_check} UTC</p>
      
            {graphData ? (
              data.added && isNewSite(data.added) ? (
                <div className="items-center py-3 px-8 pb-8 text-center">
                  <div className="rounded-2xl drop-shadow p-8 my-5">
                    <h3 className="text-4xl font-semibold dark:text-white mb-4">New Site Added</h3>
                    <p className="dark:text-white mb-2 text-xl">
                      This site was added less than 24 hours ago. Our system is currently collecting data to generate accurate uptime statistics.
                    </p>
                    <p className="dark:text-white italic">
                      Please check back soon to view your site's performance metrics.
                    </p>
                  </div>
                </div>
              ) : (
                <GraphData 
                  graphData={graphData}
                  isMonthDataVisible={isMonthDataVisible}
                  showMonthData={showMonthData}
                  selectedTimePeriod={selectedTimePeriod}
                  setSelectedTimePeriod={setSelectedTimePeriod}
                />
              )
            ) : (<></>)}
          </div>
          
          <div className="px-4 md:px-10 max-w-[1400px] mx-5 md:mx-auto">
            <div className="flex flex-col lg:flex-row lg:gap-6">
              <div className="w-full lg:w-1/2">
                {failures ? (
                  <Failures 
                    failures={failures}
                    isFailuresVisible={isFailuresVisible}
                    showFailures={showFailures}
                    mode={mode}
                  />
                ) : (
                  <div className='my-5 text-center'>
                    <h4 className='dark:text-white text-xl'>No downtime has been documented<br /> for this website. Awesome!</h4>
                  </div>
                )}
              </div>
              
              <div className="w-full lg:w-1/2">
                {notifications ? (
                  <Notifications 
                    notifications={notifications}
                    isNotificationsVisible={isNotificationsVisible}
                    showNotificationData={showNotificationData}
                    mode={mode}
                  />
                ) : (
                  <div className='my-5 text-center'>
                    <h4 className='dark:text-white text-xl'>
                      {failures ? 'No notifications has been documented<br /> for this website. Awesome!' : 'No notifications either!'}
                    </h4>
                  </div>
                )}
              </div>
            </div>

            <div className='pt-10'>
              {isDeleting ? (
                <div className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  <FaSpinner className="text-white animate-spin" />
                </div>
              ) : (
                <a onClick={handleDelete} className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-md font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 cursor-pointer">
                  Delete Website
                </a>
              )}
            </div>
          </div>
        </div>  
      ) : (
        <p className="text-2xl justify-center flex items-center text-center">
          <span>Loading Site...</span>
          <FaSpinner className="text-sm ml-2 animate-spin" />
        </p>
      )}
    </div>
  )
}

export default Site

/*
<code><pre>{JSON.stringify(data, null, 2)}</pre></code>
<code><pre>{JSON.stringify(failures, null, 2)}</pre></code>
*/
