import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaPowerOff, FaSpinner } from "react-icons/fa";

import { useSettings } from '../context/SettingsContext'
import { API_URL } from './parts/Constants';
import Button from './parts/Button';

const Home = () => {
  const settings = useSettings();
  const [data, setData] = useState(null);
  const [methods, setMethods] = useState(null);
  const [allowed, setAllowed] = useState(0)

  /**
   * 
   */
  useEffect(() => {
    const token = settings.userToken;
    document.title = 'Home | SiteStatusMonitoring.com';

    if (token && !data) {
      var myHeaders = new Headers();
      myHeaders.append("wsm-session-token", token);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(API_URL + '/getallsites', requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.code === 200) {
            if (result.sites) {
              setData(result.sites)
            }
            setAllowed(result.more)
            //console.log(data);
          } else {
            setMethods(result.methods)
            //console.log(result)
          }
        })
        .catch(error => console.log('error', error));
    }
  });

  return (
    <div className="relative pt-24 pb-32">
      <div className="px-4 md:px-10 max-w-5xl mx-5 md:mx-auto">
        {data ? (
          <>
            <h1 className='mb-8 dark:text-white'>Sites we are monitoring for you.</h1>
            {data && data.length > 0 ? (
              data.map((site, index) => (
                <Link to={'/site/'+site.id} key={index} className="py-3 px-8 md:min-w-full text-blueGray-500 bg-secondary rounded-2xl drop-shadow font-bold mb-5 no-underline cursor-pointer flex items-center justify-between">
                  <span>{site.title}</span>
                  <span className='flex'>
                    <span className='text-sm font-normal'>Status:</span>
                    <FaPowerOff title='Current Status' className={`ml-2 text-xl ${(site.status == 1) ? 'text-green-500' : 'text-red-500'}`} />
                  </span>
                </Link>
              ))
            ) : (
              <p className='text-blueGray-500 text-xs flex'>
                <span>Loading Sites...</span>
                <FaSpinner className='text-sm ml-2 animate-spin' />
              </p>
            )}

            {allowed && (<Button href="/add/" title="Add Site" icon="FaCirclePlus" extraClasses="py-2 px-4 mt-14" />)}
          </>
        ) : (
          <div className='text-center dark:text-white'>
            <h1>Welcome!</h1>
            <p className='text-lg font-bold'>Thank you for trying TheUptime.bot! We take pride in helping our customers get ahead of website downtime!</p>
            <br />
            {methods ? (
              <>
                <p className='mb-8'>Now that you have a verified contact method, you can start adding sites for us to monitor. Get started below.</p>
                <Button href="/add/" title="Add Site" icon="FaCirclePlus" extraClasses="py-2 px-4" />
              </>
            ) : (
              <>
                <p className='mb-8'>To get started, you will need to add a contact method for us to use when a site is down. You will need to verify this method before hand as well. Don't worry. We walk you through the process. Click the button below to get started.</p>
                <Button href="/settings/methods" title="Add Contact Method" icon="FaCirclePlus" extraClasses="py-2 px-4" />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Home